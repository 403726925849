const APIBASE_URL = "http://193.203.162.101:8001/"; 
// const APIBASE_URL = `http://${window.location.hostname}:8443/`;
// const APIBASE_URL = "http://127.0.0.1:8000/";
const MONGOAPI_BASE_URL = "http://193.203.162.101:8000/";
// const MONGOAPI_BASE_URL = "http://127.0.0.1:8000/";

module.exports = {
  BASE_URL: APIBASE_URL,  
  CLIENT_LOGIN: APIBASE_URL + 'employee/admin_login',
  CLIENT_LIST: APIBASE_URL + 'client/list',
  CLIENT_CREATE: APIBASE_URL + 'client/create',
  CLIENT_DELETE: APIBASE_URL + 'client/delete',
  CLIENT_UPDATE: APIBASE_URL + 'client/update',
  LIST_ORGANIZATION: APIBASE_URL + 'organization/list',
  LIST_SCREENSHOT: APIBASE_URL + 'screen/list',
  LIST_ACTIVITY: APIBASE_URL + 'activity_tracker/list',
  EMPLOG: APIBASE_URL + 'activity_tracker/emplog/',
  FILTER_EMPLOYEE: APIBASE_URL + 'activity_tracker/list_filter_employee',
  FILTER_SCREENSHOT: APIBASE_URL + 'list_filter_screen',

  ADD_DEPARTMENT: APIBASE_URL + 'depart/department/create',
  LIST_DEPARTMENT: APIBASE_URL + 'depart/department/list',
  DELETE_DEPARTMENT: APIBASE_URL + 'depart/department/delete',
  LIST_SUBDEPARTMENT: APIBASE_URL + 'sub_depart/sub_departments',
  DELETE_SUBDEPARTMENT: APIBASE_URL + 'sub_depart/delete/',
  ADD_SUBDEPARTMENT: APIBASE_URL + 'sub_depart/sub_departments/create',
  LIST_ROLE: APIBASE_URL + 'role_access/list',
  ADD_ROLE: APIBASE_URL + 'role_access/create',
  DELETE_ROLE: APIBASE_URL + 'role_access/delete/',
  REPORT_PERSON1: APIBASE_URL + 'employee/list_depart',
  REPORT_PERSON2: APIBASE_URL + 'employee/list_subdepart',
  GET_SUBDEPT_BYDEPT: APIBASE_URL + 'sub_depart/sub_depart_list',

  ADD_Employee: APIBASE_URL + 'employee/create',
  LIST_EMPLOYEE: APIBASE_URL + 'employee/list',
  ALL_LIST_EMPLOYEE: APIBASE_URL + 'employee/listall',
  EXPENDABLE_EMPLOYEE_LIST: APIBASE_URL + 'employee/get_employee_report',
  DELETE_EMPLOYEE: APIBASE_URL + 'employee/delete',

  EDITEMPLOYEE: APIBASE_URL + 'employee/employee_by/',
  UPDATEEMPLOYEE: APIBASE_URL + 'employee/update/',

  ADD_DESIGNATION: APIBASE_URL + 'roles/create',
  LIST_DESIGNATION: APIBASE_URL + 'roles/list',
  DELETE_DESIGNATION: APIBASE_URL + 'roles/designation/delete',

  LIST_LOCATION: APIBASE_URL + 'location/list',
  LIST_REPORT: APIBASE_URL + 'employee/list_reporting',

  LIST_PROJECT: APIBASE_URL + 'project/list',
  ADD_PROJECT: APIBASE_URL + 'project/create',
  UPDATE_PROJECT: APIBASE_URL + 'project/update',
  DELETE_PROJECT: APIBASE_URL + 'project/delete',


  LIST_ADD_PROJECT: APIBASE_URL + 'add_project/list',
  DELETE_ADD_PROJECT: APIBASE_URL + 'add_project/delete',
  CREATE_ADD_PROJECT: APIBASE_URL + 'add_project/create',
  UPDATE_ADD_PROJECT: APIBASE_URL + 'add_project/update',

  LIST_SCREEN_INTERVAL: APIBASE_URL + 'screenshot_interval/list',
  ADD_SCREEN_INTERVAL: APIBASE_URL + 'screenshot_interval/create',
  UPDATE_SCREEN_INTERVAL: APIBASE_URL + 'screenshot_interval/update/',
  DELETE_SCREEN_INTERVAL: APIBASE_URL + 'screenshot_interval/delete/',

  UPDATEDEPARTMENT: APIBASE_URL + 'depart/update/',
  UPDATESUBDEPARTMENT: APIBASE_URL + 'sub_depart/update/',
  UPDATEROLE: APIBASE_URL + 'role_access/update/',

  TODOCREATE: APIBASE_URL + 'todo/create',
  TODO_UPDATE: APIBASE_URL + 'todo/update/',
  TODO_LIST: APIBASE_URL + 'todo/list',
  TODO_DELETE: APIBASE_URL + 'todo/delete/',
  CREATECATEGORY: APIBASE_URL + 'todo_category/create',
  LISTCATEGORY: APIBASE_URL + 'todo_category/list',
  CATEGORY_DELETE: APIBASE_URL + 'todo_category/delete/',
  PROJECT_TODOLIST: APIBASE_URL + 'todo/list_project',
  PROJECT_TODO_EMPLOYEE_LIST: APIBASE_URL + 'timesheets/list',

  LIST_EMPLOYEEDEVICE: APIBASE_URL + 'employee_device/list',
  DELETE_EMPLOYEEDEVICE: APIBASE_URL + 'employee_device/delete/',
  ADDDEVICE: APIBASE_URL + 'employee_device/create',
  UPDATEDEVICE: APIBASE_URL + 'employee_device/update/',

  MESSAGE_CREATE: APIBASE_URL + 'project_discussion/create',
  MESSAGE_LIST: APIBASE_URL + 'project_discussion/list',
  BUDGET_LIST: APIBASE_URL + 'timesheets/list_budget?project_id=',


  LISTTIMEOFF: APIBASE_URL + 'timeoff/list',
  CREATETIMEOFF: APIBASE_URL + 'timeoff/create',

  LISTSUBSCRIPTION: APIBASE_URL + 'api/subscriptions',
  ADDSUBSCRIPTIONPLAN: APIBASE_URL + 'api/subscriptions/create',
  DELETESUBSCRIPTIONPLAN: APIBASE_URL + 'api/subscriptions/delete/',
  EDITSUBSCRIPTIONPLAN: APIBASE_URL + 'api/subscriptions/update/',

  LISTSCHEDULE: APIBASE_URL + 'scheduling/list',
  UPDATE_SCHEDULE: APIBASE_URL + 'scheduling/update/',
  DELETE_SCHEDULE: APIBASE_URL + 'scheduling/delete/',
  CREATE_SCHEDULE: APIBASE_URL + 'scheduling/create',

  LIST_EMPLOYEE_SCHEDULE: APIBASE_URL + 'employee_scheduling/list_employee_scheduling',

  EMPLOYEESCHEDULING: APIBASE_URL + 'employee_scheduling/list',
  CREATEEMPLOYEESCHEDULE: APIBASE_URL + 'employee_scheduling/create',
  DELETE_EMPLOYEE_SCHEDULE: APIBASE_URL + 'employee_scheduling/delete/',
  UPDATE_EMPLOYEE_SCHEDULE: APIBASE_URL + 'employee_scheduling/update/',

  SYNCEMP: APIBASE_URL + 'employee/sync_emp',
  SYNCDEPARTMENT: APIBASE_URL + 'depart/department/sync',

  ADMIN_LOGIN: APIBASE_URL + 'customer/admin/login',
  CHECK_LICENSE: APIBASE_URL + 'customer/check_license',
  CHECK_VALID: MONGOAPI_BASE_URL + 'client/valid',

  LIST_CALENDAR: APIBASE_URL + 'events/list',
  ADD_CALENDAR: APIBASE_URL + 'events/create',
  UPDATE_CALENDAR: APIBASE_URL + 'events/update/',

  VALID_CLIENT_LOGIN: MONGOAPI_BASE_URL + 'client/valid',
  NEW_CLIENT_LOGIN: MONGOAPI_BASE_URL + 'client/login',
  GET_TOKEN: APIBASE_URL + 'employee/get_token/',

  //CATEGORY
  CREATE_CATEGORY: APIBASE_URL + 'api/categories/add',
  UPDATE_CATEGORY: APIBASE_URL + 'api/category/update/',
  LIST_CATEGORY: APIBASE_URL + 'api/categories',
  DELETE_CATEGORY: APIBASE_URL + 'api/category/delete/',
  //subcategory
  CREATE_SUBCATEGORY: APIBASE_URL + 'api/subcategories/add',
  LIST_SUBCATEGORY: APIBASE_URL + 'api/subcategories?category_id=',
  UPDATE_SUBCATEGORY: APIBASE_URL + 'api/subcategories/update/',
  DELETE_SUBCATEGORY: APIBASE_URL + 'api/subcategories/delete/',

  PENDING_DEVICE_LIST: APIBASE_URL + 'employee_device/list_pending_dev',
  PENDING_DEVICE_EMPLOYEE_LIST: APIBASE_URL + 'employee_device/list_employee_by_device',
  DELETE_PENDING_DEVICE: APIBASE_URL + 'employee_device/delete_Pending_device/',

  //KT 
  KT_LIST: APIBASE_URL + 'api/knowledge-transfers',
  ADD_KT: APIBASE_URL + 'api/knowledge-transfers/add',
  DELETE_KT: APIBASE_URL + 'api/delete_kt/',
  UPDATE_KT: APIBASE_URL + 'api/update_kt/',
  KT_DETAIL: APIBASE_URL + 'api/list_kt_id/',

  //KT details
  ADD_KT_DETAILS: APIBASE_URL + 'api/kt-details/add',
  KT_VIEW_UPDATE: APIBASE_URL + '/api/view-update',

  // Task Assign
  LIST_TASKASSIGN: APIBASE_URL + 'api/list-task-assign/',

  //Rating Update
  UPDATE_RATING: APIBASE_URL + 'api/rating-update',

  //ADMIN
  SUB_ADMIN: APIBASE_URL + 'api/subtransactions_add',
  VALID_SUB_ADMIN: APIBASE_URL + 'api/subtransactions/',
  //Search Employee
  EMPLOYEE_SEARCH: APIBASE_URL + 'employee/list_search',
  //Reporting Employee and Memebers
  REPORTINGEMPLOYEE: APIBASE_URL + 'employee/list_employee_report',
  UPLOADKAFAA: APIBASE_URL + 'api/kafaa_upload',

  //Delete Data
  DELACTIVITY :APIBASE_URL + 'api/del_activity',
  DELSCREEN   :APIBASE_URL + 'api/del_screen',
  DELDEVICE   :APIBASE_URL + 'api/del_device',
}