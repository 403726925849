import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FetchData from "../fetch-api/Apifetch";
import { LIST_ACTIVITY, LIST_REPORT } from "./ApiRoute";

export const handleImageError = (event) => {
  event.target.src = `/images/imageplaceholder.jpeg`; // Path to your default image
};

export const handleUserImageError = (event) => {
  event.target.src = '/images/add-user1.png';
};

export const handleProImageError = (event) => {
  event.target.src = `/images/images.png`; // Path to your default image
};

export const TimeCalculator = ({ application, onTimeCalculated }) => {
  const timeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const secondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
    const seconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (application.Total_Usage) {
      const usageInSeconds = timeToSeconds(application.Total_Usage);
      const formattedTime = secondsToTime(usageInSeconds);
      onTimeCalculated(formattedTime, usageInSeconds);
    }
  }, [application, onTimeCalculated]);

  return null; // No UI needed as we only calculate and return the value
};

export const ActivityTrackingApi = async () => {
  try {
    const res = await FetchData(LIST_ACTIVITY + `?page=1&limit=7`, 'GET', null, true, false);
    console.log(res);
  } catch (err) {
    console.log(err);
  }
};

export const getStatus = (ID) => {
  const statusMap = {
    "0": "In Progress",
    "1": "Cancelled",
    "2": "Completed",
    "3": "Yet to start",
  };

  return statusMap[ID] || "Unknown Status";
};

export const FormatTime = (input) => {
  const cleanedInput = input.replace(/\D/g, '');
  const hours = cleanedInput.substring(0, 2);
  const minutes = cleanedInput.substring(2, 4);
  const seconds = cleanedInput.substring(4, 6);

  let formattedTime = '';
  if (hours) formattedTime = hours;
  if (minutes) formattedTime += (hours ? ':' : '') + minutes;
  if (seconds) formattedTime += (minutes ? ':' : '') + seconds;

  return formattedTime;
};

export const Listicons = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Define navigate here

  return (
    <>
      <button
        className={`${location.pathname === '/application-track' ? '' : ''} hoveringpad`}
        onClick={() => navigate('/application-track')}
      >
        <img
          src={location.pathname === '/application-track' ? '/images/activityactive.png' : '/images/icons/activity.png'}
          style={{ width: '25px', height: '23px' }}
          alt="Application Track"
        />
      </button>
      <button
        className={`${location.pathname === '/Url-track' ? '' : ''} hoveringpad`}
        onClick={() => navigate('/Url-track')}
      >
        <img
          src={location.pathname === '/Url-track' ? '/images/linkactive.png' : '/images/icons/urls.png'}
          style={{ width: '25px', height: '23px' }}
          alt="URL Track"
        />
      </button>
      <button
        className={`${location.pathname === '/screen-shots' ? '' : ''} hoveringpad`}
        onClick={() => navigate('/screen-shots')}
      >
        <img
          src={location.pathname === '/screen-shots' ? '/images/landscapeactive.png' : '/images/icons/capture.png'}
          style={{ width: '25px', height: '23px' }}
          alt="Screenshots"
        />
      </button>
      <button
        className={`${location.pathname === '/keystroke' ? '' : ''} hoveringpad`}
        onClick={() => navigate('/keystroke')}
      >
        <img
          src={location.pathname === '/keystroke' ? '/images/mouseactive.png' : '/images/icons/keyboard.png'}
          style={{ width: '25px', height: '23px' }}
          alt="Keystroke"
        />
      </button>
      <button
        className={`${location.pathname === '/locations' ? '' : ''} hoveringpad`}
        onClick={() => navigate('/locations')}
      >
        <img
          src={location.pathname === '/locations' ? '/images/locationactive.png' : '/images/icons/location.png'}
          style={{ width: '25px', height: '23px' }}
          alt="Locations"
        />
      </button>
    </>
  );
};

export const calculateDaysBetween = (fromDate, toDate) => {
  if (!fromDate || !toDate) return ''; // If any date is missing, return empty string

  const from = new Date(fromDate?.split(/[\sT]/)[0]); // Convert to Date object
  const to = new Date(toDate?.split(/[\sT]/)[0]); // Convert to Date object

  const timeDifference = to - from;
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  return daysDifference;
};

export const RemainingDays = (date) => {
  const targetDate = new Date(date);
  const currentDate = new Date();
  const timeDifference = targetDate.getTime() - currentDate.getTime();
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  console.log("daysRemaining", daysRemaining);
  return daysRemaining;
};

export const NOData = () => {
  return (
    <div className="container-fluid pb-2 mt-3">
      <div
        className="container-fluid w-100 d-flex justify-content-center align-items-center"
        style={{ height: 200 }}
      >
        <div className="w-100 d-flex align-items-center row-gap-1 flex-column justify-content-center">
          <span className="fontsubtitle font-weight-bold text-dark1">
            No Data Found?
          </span>
          <a>
            <span
              className="fontsubtitle font-weight-1 text-connect1"
              role="button"
            >
              Try later Again
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export const calculateOvertimedata = (overtime, employeeSchedule) => {
  if (!overtime || !employeeSchedule) {
    console.log("Invalid input times for calculation.");
    return "00:00:00";
  }
  console.log(employeeSchedule);
  console.log(overtime);

  try {
    // Ensure input strings are in hh:mm:ss format
    console.log(employeeSchedule);
    const overtimeStr = overtime.includes(":") && overtime.split(":").length === 2 
      ? `${overtime}:00` 
      : overtime;
    const scheduleStr = employeeSchedule.includes(":") && employeeSchedule.split(":").length === 2 
      ? `${employeeSchedule}:00` 
      : employeeSchedule;

    // Parse the times as dates on the same day
    console.log(overtimeStr.split(" ")[1]);
    const overtimeDate = new Date(`1970-01-01T${overtimeStr?.split(" ")[1]}`);
    const scheduleDate = new Date(`1970-01-01T${scheduleStr}`);

    console.log(overtimeDate);
    console.log(scheduleDate);
    // Check for invalid dates
    if (isNaN(overtimeDate.getTime()) || isNaN(scheduleDate.getTime())) {
      console.log("Invalid time format.");
      return "00:00:00";
    }

    // Ensure overtime is not before the scheduled end time
    if (overtimeDate < scheduleDate) {
      console.log("Overtime is before the scheduled end time.");
      return "00:00:00";
    }

    // Calculate the difference in milliseconds
    const differenceInMs = overtimeDate - scheduleDate;

    // Convert the difference to seconds and format as hh:mm:ss
    const totalSeconds = Math.floor(differenceInMs / 1000);
    console.log("secondsToTimeData(totalSeconds)",secondsToTimeData(totalSeconds));
    return secondsToTimeData(totalSeconds);
  } catch (err) {
    console.error("Error calculating overtime:", err);
    return "00:00:00";
  } 
};

export const secondsToTimeData = (totalSeconds) => {
  if (!totalSeconds && totalSeconds !== 0) {
    return "00:00:00";
  }
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  console.log([hours, minutes, seconds].map(val => String(val).padStart(2, '0')).join(':'));
  console.log('hms',[hours, minutes, seconds].map(val => String(val).padStart(2, '0')).join(':'));
  return [hours, minutes, seconds].map(val => String(val).padStart(2, '0')).join(':');
};