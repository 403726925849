import React, { useEffect, useState } from "react";
import FetchData from "../../fetch-api/Apifetch";
import {
  LIST_DEPARTMENT,
  ADD_DEPARTMENT,
  DELETE_DEPARTMENT,
  UPDATEDEPARTMENT,
  LIST_SUBDEPARTMENT,
  ADD_SUBDEPARTMENT,
  DELETE_SUBDEPARTMENT,
  UPDATESUBDEPARTMENT,
  GET_SUBDEPT_BYDEPT,
  SYNCDEPARTMENT,
} from "../../utils/ApiRoute";
import { Shimmer } from "../../common/Shimmer";
import "./common.css";
import { toast } from "react-toastify";
import PagesHeadings from "../../common/PagesHeadings";
import CommonModal from "../../common/CommonModal";
import SuccessModal from "../../common/SuccessModal";
import { t } from "i18next";
import CommonLoader from "../../common/CommonLoader";
import { DeleteButton, EditButton, ReactModal } from "../../common/CommonUi";
import { NOData } from "../../utils/Function";

const Department = () => {
  const [Department, setDepartment] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingnew, setLoadingNew] = useState(true);
  const [newDepartmentTitle, setNewDepartmentTitle] = useState("");
  const [editId, seteditId] = useState();
  const [subeditId, setsubeditId] = useState();
  const [filterOption, setFilterOption] = useState();
  const [language, setlanguage] = useState(localStorage.getItem("lang"));
  const [subDepartment, setsubDepartment] = useState([]);
  const [DepartName, setDepartName] = useState();
  const [modalstate, setModalstate] = useState();
  const [ModalView, setModalView] = useState(false);
  const [ModalAddView, setModalAddView] = useState(false);
  const [modalContent, setModalContent] = useState({ icon: 0, desc: "" });
  const [responsive, setresponsive] = useState(false);
  const [isLoadApi, setIsLoadApi] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [FilterData, setFilterData] = useState({
    from: "",
    to: "",
  });
  const [subdepartmentData, setsubdepartmentData] = useState("");
  useEffect(() => {
    GetDepartment();
    ListSubDepartment();
  }, []);

  const GetDepartment = async () => {
    setLoading(true);
    try {
      const res = await FetchData(
        `${LIST_DEPARTMENT}?filter=${filterOption}&from_date=${FilterData.from}&to_date=${FilterData.to}`,
        "GET",
        null,
        true,
        false
      );
      if (res.status) {
        console.log(res.data);
        setDepartment(res.data);
        // setDepartment([]);
        setDepartName(res.data[0]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    subDepartmentByDepartment();
  }, [DepartName]);

  const ListSubDepartment = async () => {
    try {
      const res = await FetchData(LIST_SUBDEPARTMENT, "GET", null, true, false);
      console.log(res.data);
      setsubDepartment(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const DepartmentUpdate = async () => {
    if (modalstate == 0) {
      const requestData = {
        title: newDepartmentTitle,
      };
      try {
        setIsLoadApi(true);
        const res = await FetchData(
          UPDATEDEPARTMENT + editId,
          "PUT",
          JSON.stringify(requestData),
          true,
          false
        );
        console.log(res);
        setIsLoadApi(false);
        setModalAddView(true);
        setModalContent({ icon: 1, desc: "Updated Department Successfully." });
        GetDepartment();
        seteditId(null);
        closeModal();
      } catch (err) {
        console.log(err);
        setIsLoadApi(false);
      }
    } else if (modalstate == 1) {
      const requestData = {
        title: subdepartmentData,
        dept_id: DepartName.id,
      };
      try {
        setIsLoadApi(true);
        const res = await FetchData(
          UPDATESUBDEPARTMENT + editId,
          "PUT",
          JSON.stringify(requestData),
          true,
          false
        );
        console.log(res);
        setIsLoadApi(false);
        setModalAddView(true);
        setModalContent({
          icon: 1,
          desc: "Updated Sub Department Successfully.",
        });
        // ListSubDepartment()
        subDepartmentByDepartment();
        closeModal();
      } catch (err) {
        console.log(err);
        setIsLoadApi(false);
      }
    }
  };

  const addDepartment = async () => {
    if (modalstate == 0) {
      const requestData = {
        title: newDepartmentTitle,
      };
      try {
        setIsLoadApi(true);
        const res = await FetchData(
          ADD_DEPARTMENT,
          "POST",
          JSON.stringify(requestData),
          true,
          false
        );
        setIsLoadApi(false);
        if (res.status) {
          console.log("Department added successfully:", res.data);
          GetDepartment();
          setModalAddView(true);
          setModalContent({ icon: 1, desc: "Added Department" });
          closeModal(); // Close the Modal
        } else {
          console.log("Failed to add department:", res.message);
        }
      } catch (err) {
        console.log(err);
        setIsLoadApi(false);
      }
    } else if (modalstate == 1) {
      const requestData = {
        title: subdepartmentData,
        dept_id: DepartName.id,
      };
      try {
        setIsLoadApi(true);
        const res = await FetchData(
          ADD_SUBDEPARTMENT,
          "POST",
          JSON.stringify(requestData),
          true,
          false
        );
        setIsLoadApi(false);
        // ListSubDepartment()
        subDepartmentByDepartment();
        closeModal();
        // SuccessModal({icon:1,desc:'Added Sub Department',setShowModal:setModalAddView,showModal:ModalAddView})
        setModalAddView(true);
        setModalContent({ icon: 1, desc: "Added Sub Department" });
      } catch (err) {
        console.log(err);
        setIsLoadApi(false);
      }
    }
  };

  const deleteDepartment = async (id) => {
    const requestData = {
      title: newDepartmentTitle,
      status: 1,
    };
    try {
      const res = await FetchData(
        UPDATEDEPARTMENT + editId,
        "PUT",
        JSON.stringify(requestData),
        true,
        false
      );
      console.log(res);
      setModalAddView(true);
      setModalContent({ icon: 1, desc: "Deleted Department Successfully." });
      GetDepartment();
      
      seteditId(null);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  const ShowsubModaldelete = (items) => {
    setModalView(true);
    setModalstate(1);
    setsubeditId(items.SubDepartmentId);
    setsubdepartmentData(items.SubDepartmentTitle);
  };
  const ShowModaldelete = (item) => {
    setModalView(true);
    setModalstate(0);
    seteditId(item.id);
    setNewDepartmentTitle(item.title);
  };
  const DeleteSubDepartment = async (id) => {
    const requestData = {
      status: 1,
      title: subdepartmentData,
      dept_id: DepartName.id,
    };
    try {
      const res = await FetchData(
        DELETE_SUBDEPARTMENT + subeditId,
        "DELETE",
        JSON.stringify(requestData),
        true,
        false
      );
      console.log(res);
      
      // Refresh the sub-department list after successful delete
      subDepartmentByDepartment();  // You might want to call this after a delete
      ListSubDepartment();  // You can also call this, depending on the implementation
  
      setModalAddView(true);
      setModalContent({
        icon: 1,
        desc: "Deleted Sub Department Successfully.",
      });
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };
  

  const subDepartmentByDepartment = async () => {
    setLoadingNew(true);
    try {
      const res = await FetchData(
        GET_SUBDEPT_BYDEPT + `?dept_id=${DepartName?.id}`,
        "GET",
        null,
        true,
        false
      );
      console.log(res.data);
      setsubDepartment(res.data);
      setLoadingNew(false);
    } catch (err) {
      console.error(err);
      setLoadingNew(false);
    }
    setLoadingNew(false);
  };

  const closeModal = () => {
    setOpenModal(false);

    const modal = document.getElementById("exampleModal");
    const editmodal = document.getElementById("exampleModaledit");
    if (modal || editmodal) {
      modal.querySelector(".close").click();
    }
    setNewDepartmentTitle("");
    setsubdepartmentData("");
  };

  const InputField = (title, name, type, value, onchange) => {
    return (
      <div className="d-flex h-100 my-4">
        <label
          htmlFor="recipient-name"
          className="col-form-label col-5"
          style={{ fontSize: 15 ,fontWeight:'bold',color:'#495057'}}
        >
          {title}
        </label>
        <input
          type={type}
          className="form-control "
          placeholder={t("Enter_title")}
          id="recipient-name"
          name={name}
          value={value}
          style={{ fontSize: 14 ,borderRadius:'10px'}}
          onChange={(e) => onchange(e.target.value)}
        />
      </div>
    );
  };
  
  const [rotate, setRotate] = useState(false);
  const [rotate1, setRotate1] = useState(false);
  const handleReload = () => {
    setRotate(true);
    syncData();
  };
  const handleReload1 = () => {
    setRotate1(true);
    syncData();
  };

  const syncData = async () => {
    try {
      const res = await FetchData(SYNCDEPARTMENT, "GET", null, true, false);
      console.log(res);
      GetDepartment();
      ListSubDepartment();
      setRotate(false);
      setRotate1(false);
    } catch (err) {
      console.log(err);
      GetDepartment();
      ListSubDepartment();
      setRotate(false);
      setRotate1(false);
    }
  };

  const ModalFields = (
    <>
      {InputField(
        modalstate == 0 ? t("Add_name") : modalstate == 1 ? t("sub_name") : "",
        "title",
        "text",
        modalstate == 0
          ? newDepartmentTitle
          : modalstate == 1
          ? subdepartmentData
          : "",
        modalstate === 0
          ? setNewDepartmentTitle
          : modalstate === 1
          ? setsubdepartmentData
          : ""
      )}
    </>
  );

  const onHandleOpenModal = (state, type, data) => {
    setOpenModal(true);
    setModalstate(state);
    if (type == 0) {
      seteditId();
      setNewDepartmentTitle();
      setsubdepartmentData();
    } else {
      if (state == 0) {
        seteditId(data.id);
        setNewDepartmentTitle(data.title);
      } else {
        seteditId(data.SubDepartmentId);
        setsubdepartmentData(data.SubDepartmentTitle);
      }
    }
  };

  return (
    <>
      <PagesHeadings
        PageHeader={t("Department_list")}
        insidehead={t("User_Management")}
      />
      {loading ? (
        <Shimmer />
      ) : (
        <div className="md:col-span-12 lg:col-span-7 xl:col-span-7 col-span-12 row mx-auto">
          <div
            className={`box !p-[20px] col-lg-6 shadow-sm ${
              responsive ? "d-sms-none" : "col-sms-12"
            }`}
          >
            <div className="box-header !border-b-0 !p-0 flex justify-between">
              <h4 className={`box-title mb-2`}>{t("Department_list")}</h4>
              <div className="d-flex align-items-center gap-3">
                <img
                  src="/images/icons/reload.png"
                  role="button"
                  className={rotate ? "rotating" : ""}
                  onClick={() => handleReload()}
                  style={{ width: "25px", height: "25px" }}
                />
                <button
                  type="button"
                  className="new-project-btn btn-wfm btn-sm "
                  data-toggle="modal"
                  data-target="#exampleModal"
                  data-whatever="@mdo"
                  onClick={() => {
                    onHandleOpenModal(0, 0);
                  }}
                >
                  {t("Add")} {t("Department")}
                </button>
              </div>
            </div>
            <div
              className="card my-2"
              style={{ height: "65vh", overflowY: "auto" }}
            >
              <div className="table-responsive">
                <table className="table table-bordered table-hover table-sm  whitespace-nowrap min-w-full mb-0 ">
                <thead className='bg-primary-subtle'>
                    <tr className='border-b bg-primary-subtle'>
                      <th scope="col" className={`${language == 'ar' ? 'text-end' : 'text-center'} bg-primary-subtle`}> {t('Numbers')}</th>
                      <th scope="col" className={`${language == 'ar' ? 'text-end' : 'text-center'} bg-primary-subtle`} style={{ width: '40%' }}> {t('Department')}</th>
                      <th scope="col" className={`${language == 'ar' ? 'text-end' : 'text-center'} bg-primary-subtle`}> {t('Action')}</th>
                      </tr>
                  </thead>
                  <tbody >
                    {Department.map((item, index) => (
                      <tr
                        key={index}
                        className={`${
                          DepartName.id === item.id ? "table-info" : ""
                        }`}
                        role="button"
                        onClick={() => {
                          setDepartName(item);
                          setresponsive(true);
                        }}
                      >
                        <td scope="col" style={{verticalAlign:'middle'}} className={`${language == 'ar' ? 'text-end' : 'text-center'} ${DepartName.id === item.id ? 'bg-lightblue' : ''}`}> {index + 1}</td>
                        <td scope="col" style={{verticalAlign:'middle'}} className={`${language == 'ar' ? 'text-end' : 'text-center'}  ${DepartName.id === item.id ? 'bg-lightblue' : ''}`}> {item.title}</td>
                        <td className={`py-1 ${language == 'ar' ? 'text-end' : 'text-start'} ${DepartName.id === item.id ? 'bg-lightblue' : ''}`}>
                          {/* <div className='hstack flex gap-3 text-[.9375rem] '> */}
                        {/* <td className="text-center"> */}
                          <div className="d-flex justify-content-center gap-2">
                            <EditButton
                              onClick={() => {
                                onHandleOpenModal(0, 1, item);
                              }}
                            />
                            <DeleteButton
                              onClick={() => {
                                ShowModaldelete(item);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                    {Department.length === 0 && (
                      <tr>
                        <td colSpan="3" className="text-center text-muted">
                          <NOData />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className={`box !p-[20px] col-lg-6 shadow-sm ${
              !responsive ? "d-sms-none" : "col-sms-12"
            }`}
          >
            <a className="d-lg-none" onClick={() => setresponsive(false)}>
              <i class="ri ri-close-large-fill" aria-hidden="true"></i>
            </a>
            <div className="box-header !border-b-0 !p-0 flex justify-between align-items-start">
              <h4 className={`box-title mb-2 `} style={{ width: "40%" }}>
                {t("Sub_Department_of")} {DepartName?.title}{" "}
              </h4>
              <div className="d-flex align-items-center gap-3">
                <img
                  src="/images/icons/reload.png"
                  role="button"
                  className={rotate1 ? "rotating" : ""}
                  onClick={() => handleReload1()}
                  style={{ width: "25px", height: "25px" }}
                />
                <button
                  type="button"
                  className="new-project-btn  btn-wfm btn-sm "
                  data-toggle="modal"
                  data-target="#exampleModal"
                  data-whatever="@mdo"
                  onClick={() => {
                    onHandleOpenModal(1, 0);
                  }}
                >
                  {t("Add")} {t("Sub_Department")}
                </button>
              </div>
            </div>
            {loadingnew ? (
              <Shimmer />
            ) : (
              <div
                className="card my-2"
                style={{ height: "65vh", overflowY: "scroll" }}
              >
                <div className="table-responsive">
                  <table className="table table-bordered table-hover table-sm text-center align-middle mb-0">
                    <thead className="table-primary">
                      <tr>
                        <th scope="col" className={`${language == 'ar' ? 'text-end' : 'text-center'} bg-primary-subtle`}>
                          {t("Numbers")}
                        </th>
                        <th scope="col" className={`${language == 'ar' ? 'text-end' : 'text-center'} bg-primary-subtle`}  style={{width:'60%'}}
                        >
                          {t("Sub_Department")}{" "}
                        </th>
                        <th scope="col" className="text-center">
                          {t("Action")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subDepartment?.map((item, index) => (
                        <tr key={index}>
                          <td scope="col" style={{verticalAlign:'middle'}} className={`${language == 'ar' ? 'text-end' : 'text-center'}`}>{index + 1}</td>
                          <td scope="col" style={{verticalAlign:'middle'}} className={`${language == 'ar' ? 'text-end' : 'text-center'}`}>
                            {item.SubDepartmentTitle}
                          </td>
                          <td className="text-center">
                            <div className="d-flex justify-content-center gap-2">
                              <EditButton
                                onClick={() => onHandleOpenModal(1, 1, item)}
                              />
                              <DeleteButton
                                onClick={() => ShowsubModaldelete(item)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                      {subDepartment.length === 0 && (
                        <tr>
                          <td colSpan="3" className="text-center text-muted">
                            <NOData />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <ReactModal
        show={openModal}
        onHide={() => setOpenModal(false)}
        modalTitle={
          modalstate == 0
            ? t(
                `${editId ? `${t("Update")}` : `${t("Add")}`} ${t(
                  "Department"
                )}`
              )
            : t(
                `${editId ? `${t("Update")}` : `${t("Add")}`} ${t(
                  "Sub_Department"
                )}`
              )
        }
        fields={ModalFields}
        onCancel={() => setOpenModal(false)}
        onSubmit={() => (editId ? DepartmentUpdate() : addDepartment())}
        submitButtonText={editId ? `${t("Update")}` : `${t("Add")}`}
      />

      <CommonModal
        icon={"/images/icons/ask.png"}
        title={`Delete ${modalstate == 0 ? "Department" : "Sub Department"} ?`}
        desc={`${t("alert")} ${' '} ${
          modalstate == 0 ? t("Department") :t("Sub_Department")
        }?`}
        
        
       
                submitText={t("Delete")}
        submitAction={() => {
          modalstate == 0
            ? deleteDepartment(editId)
            : DeleteSubDepartment(subeditId);
        }}
        setShowModal={setModalView}
        showModal={ModalView}
      />

      <SuccessModal
        icon={modalContent.icon}
        desc={modalContent.desc}
        setShowModal={setModalAddView}
        showModal={ModalAddView}
      />

      {isLoadApi && <CommonLoader />}
    </>
  );
};

export default Department;
