import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import './style/style.css'
import { t } from 'i18next';
const CommonModal = ({ icon, title, desc, submitText, submitAction, setShowModal, showModal, cancel = () => { } }) => {
    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)} className="modelfilter mode mx-auto" size='mdsm'>
                <Modal.Header closeButton style={{ border: 'none' }} />
                <Modal.Body>
                    <div
                        className='container-fluid d-flex flex-column align-items-center justify-content-center'>
                        <img
                            src={icon}
                            style={{ width: '60px', height: '60px', border: "none", borderRadius: '50%' }} />
                        <span
                            className="fontsubtitle font-weight-bold my-4 mt-5 text-dark1 mt-1">
                            {desc}
                        </span>
                        <div className="mt-3 d-flex justify-content-center column-gap-3">
                            {/* <button className="fontsubtitle btn btn-outline-danger rounded-pill px-3 py-1" onClick={() => setShowModal(false)}>Cancel</button> */}
                            {submitAction && (
                                <button
                                    className="fontsubtitle btn btn-exp rounded-pill px-3 py-1"
                                    style={{ width: "100px" }}
                                    onClick={() => {
                                        setShowModal(false);
                                        cancel()
                                    }}>
                                    {t('cancel')}</button>
                            )}
                            <button
                                className="fontsubtitle btn btn-wfm rounded-pill px-3 py-1"
                                style={{ minWidth: "100px" }}
                                onClick={() => {
                                    if (submitAction) {
                                        submitAction();
                                    }
                                    setShowModal(false);
                                }}>{submitText}</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

}

export default CommonModal
