import { t } from "i18next";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const language = localStorage.getItem("lang");

export const ReactModal = ({
  show,
  onHide,
  modalTitle,
  fields,
  onCancel,
  onSubmit,
  submitButtonText = "Submit",
  cancelButtonText = `${t("cancel")}`,
  size = "md",
  isFooter = true,
}) => {
  return (
    <Modal show={show} onHide={onHide} size={size} centered>
      <Modal.Header closeButton>
        <span className="fontsubtitle font-weight-bold text-dark1 mb-0 ml-4 w-100 d-flex">
          {modalTitle}
        </span>
      </Modal.Header>
      <Modal.Body>
        <div>{fields}</div>
      </Modal.Body>
      {isFooter && (
        <Modal.Footer>
          <button className="btn btn-exp" onClick={onCancel}>
            {cancelButtonText}
          </button>
          <button className="btn btn-wfm" onClick={onSubmit}>
            {submitButtonText}
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export const InputField = (
  title,
  name,
  type,
  value,
  onchange,
  placeholder,
  col = 6,
  isObject
) => {
  return (
    <>
      <div
        className={`form-group col-md-${col} ${language == "ar" ? "text-right" : ""
          }`}
      >
        <label
          htmlFor="recipient-name"
          className="col-form-label"
          // style={{ fontSize: 14 }}
          style={{ fontSize: 15, fontWeight: 'bold', color: '#495057' }}

        >
          {title}
        </label>
        <input
          type={type}
          className="form-control"
          placeholder={placeholder}
          name={name}
          value={value}
          style={{ fontSize: 14 }}
          onChange={(e) => {
            if (isObject) {
              onchange({
                target: { name: e.target.name, value: e.target.value },
              });
            } else {
              onchange(e.target.value);
            }
          }}
        />
      </div>
    </>
  );
};
export const InputFields = (
  title,
  name,
  type,
  value,
  onchange,
  placeholder,
  col = 6,
  isObject = true
) => {
  return (
    <div
      className={`d-flex mb-3 align-items-center ${language === "ar" ? "flex-row-reverse" : ""
        }`}
    >
      <label
        htmlFor={name}
        className="col-4 col-form-label"
        style={{ fontSize: 15, fontWeight: 'bold', color: '#495057' }}
      >
        {title}
      </label>
      <div className={`col-sm-${col}`}>
        <input
          type={type}
          className="form-control "
          id={name}
          placeholder={placeholder}
          name={name}
          value={value}
          style={{ fontSize: 14, borderRadius: '6px' }}
          onChange={(e) => {
            if (isObject) {
              onchange({
                target: { name: e.target.name, value: e.target.value },
              });
            } else {
              onchange(e.target.value);
              // onchange(e);
            }
          }}
        />
      </div>
    </div>
  );
};

export const TextArea = (
  title,
  name,
  type,
  value,
  onchange,
  placeholder,
  col = 6,
  isObject
) => {
  return (
    <>
      <div
        className={`form-group col-md-${col} ${language == "ar" ? "text-right" : ""
          }`}
      >
        <label
          htmlFor="recipient-name"
          className="col-form-label"
          // style={{ fontSize: 14 }}
          style={{ fontSize: 15, fontWeight: 'bold', color: '#495057' }}

        >
          {title}
        </label>
        <textarea
          className="form-control"
          placeholder={placeholder}
          name={name}
          style={{ fontSize: 14, minHeight: "100px" }}
          value={value}
          onChange={(e) => {
            if (isObject) {
              onchange({
                target: { name: e.target.name, value: e.target.value },
              });
            } else {
              onchange(e.target.value);
            }
          }}
        />
      </div>
    </>
  );
};

export const SelectOption = (
  label,
  name,
  value,
  onChange,
  placeholder,
  options,
  col = 12
) => (
  <div className={` form-group mb-3 ${language == "ar" ? "text-right" : ""}`}>
    <label htmlFor={name} className="col-form-label" style={{ fontSize: 14, fontWeight: 'bold', color: '#495057' }}>
      {label}
    </label>
    <select
      className="form-control pr-5"
      id={name}
      name={name}
      value={value}
      style={{ fontSize: 14, height: "auto !important", }}
      onChange={(e) =>
        onChange({ target: { name: e.target.name, value: e.target.value } })
      }
      required
    >
      <option value="" disabled>
        {placeholder} {typeof options}
      </option>
      {Array.isArray(options) &&
        options?.map((option, index) => (
          <option key={index} value={option.id}>
            {option.title || option.name || option.first_name}
          </option>
        ))}
    </select>
  </div>
);


export const SelectOptions = (
  label,
  name,
  value,
  onChange,
  placeholder,
  options,
  col = 12
) => (
  <div className="form-group d-flex mb-3">
    <label
      htmlFor={name}
      className="col-form-label col-7"
      style={{ fontSize: 15, fontWeight: 'bold', color: '#495057' }}

    >
      {label}
    </label>
    <select
      className="form-control"
      id={name}
      name={name}
      value={value}
      style={{
        fontSize: "14px", // Uniform font size
        backgroundColor: "#fff", // White background for dropdown
        padding: "5px 10px", // Padding for better spacing

      }}
      onChange={(e) =>
        onChange({ target: { name: e.target.name, value: e.target.value } })
      }
      required
    >
      <option value="" disabled style={{ backgroundColor: "#fff" }}>
        {placeholder}
      </option>
      {Array.isArray(options) &&
        options?.map((option, index) => (
          <option
            key={index}
            value={option.id}
            style={{
              backgroundColor: "#fff", // Ensure white background for options
              color: "#212529", // Standard text color
            }}
          >
            {option.title || option.name || option.first_name}
          </option>
        ))}
    </select>
  </div>
);





export const EditButton = ({ onClick }) => (
  <a
    aria-label="anchor"
    role="button"
    className="ti-btn-sm ti-btn-info !rounded-full bg-info-subtle p-2"
    onClick={() => onClick()}
  >
    <img
      src="\images\icons\edit.png"
      style={{
        width: "13px",
        height: "13px",
        minWidth: "13px",
      }}
    />
  </a>
);

export const DeleteButton = ({ onClick }) => (
  <a
    aria-label="anchor"
    role="button"
    className="ti-btn-sm ti-btn-danger !rounded-full bg-danger-subtle p-2"
    onClick={() => onClick()}
  >
    <img
      src="\images\icons\delete.png"
      style={{
        width: "13px",
        height: "13px",
        minWidth: "13px",
      }}
    />
  </a>
);

export const ViewButton = ({ onClick, icon }) => (
  <a
    aria-label="anchor"
    role="button"
    className="ti-btn-sm ti-btn-info !rounded-full bg-info-subtle p-2"
    onClick={() => onClick()}
  >
    <img
      src={icon || "/images/icons/view.png"}
      style={{
        width: "13px",
        height: "13px",
        minWidth: "13px",
      }}
    />
  </a>
);
