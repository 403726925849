import Department from "../split/Department/Department";

export const english = {
  welcome: 'Welcome',
  dashboard: 'Dashboard',
  Calendar: 'Calendar',
  welcomedashboard: 'welcome back!',
  Employee_Report: 'Employee Report dashboard.',
  TODAY_WORKING_HOURS: 'TODAY WORKING HOURS',
  TOTAL_IDLE_HOURS: 'TOTAL IDLE HOURS',
  Mouse_Count: 'Mouse Count',
  Keystroke_Count: 'Keystroke Count',
  User_Management: 'User Management',
  Activity_Tracking: 'Activity Tracking',
  Project_Management: 'Project Management',
  Add: 'Add',
  Action: 'Action',
  Attendance: 'Attendance',
  Timesheet: 'Timesheet',
  Department: 'Department',
  Sub_Department: 'Sub Department',
  Sub_Department_of: 'Sub Department of',
  Role_Access: 'Role Access',
  Employee: 'Employee',
  Mouse_Keyboard: 'Mouse & Keyboard',
  Application: 'Application',
  URL: 'URL',
  Screenshots: 'Screenshots',
  Location: 'Location',
  Projects: 'Projects',
  Client: 'Client',
  Project_Teams: 'Project Teams',
  To_Dos: 'To-dos',
  Title: 'Title',
  Share: 'Share',
  Previous: 'Previous',
  Next: 'Next',
  Screenshot_Interval: 'Screenshot Interval',
  alert: 'are sure you want to delete',

  Previous_Mouse_click: 'Previous Mouse click',
  Previous_Key_Count: 'Previous Key Count',
  Keystroke_Count: 'Keystroke Count',
  Hours_spend_today: 'Hours spend today',
  paragraph: 'Visualize and analyze employee work hours for better time management and productivity.',
  Working_hours: ' Working hours',
  Idle_hours: ' Idle hours',
  Weekly_Report: 'Weekly Report',
  paragraph1: 'Report Status, Track your employee weekly report the basis of hours per day.',
  Manual_hours: 'Manual hours',
  Recent_screenshot: 'Recent Screenshots',
  Employee_Activity: 'Employee Activity',
  Employee_Attendence: 'Employee Attendence',
  Recent_Website: 'Recent Website',
  Department_list: 'Department List',
  Employee_List: 'Employee List',
  Mouse_Keyboard: 'Mouse and Keyboard',
  Application_Tracking: 'Application Tracking',
  URLTrack_Activity: 'URL-Track Activity',
  Timesheet_List: 'Timesheet List',
  TimeOff: 'Time off',
  EmployeeDevice: 'Employee Device',
  Schedule: 'Schedule',
  Numbers: 'S.no',
  Dates: 'Date',
  StartTime: 'Start Time',
  INTime: 'In Time',
  OutTime: 'Out Time',
  OpenTime: 'Open Time',
  EndTime: 'End Time',
  CloseTime: 'Close Time',
  Activity: 'Activity',
  OverTime: 'Over Time',
  BreakTime: 'Break Time',
  ManualTime: 'Manual Time',
  ScreenTime: 'Screen Time',
  IdleTime: 'Idle Time',
  WorkTime: 'Active Time',
  Time: 'Time',
  OverallProduction: 'OverAll Production',
  Export: 'Export User',
  Selectfilter: 'Select filter',
  filter: 'filter',
  LeaveData: 'Leave Data',
  CurrentWeek: 'Current Week',
  WeeklyPattern: 'Weekly Pattern',
  NoData: 'No Data Found',
  LeaveHistory: 'Leave History',
  RequestLeave: 'Request Leave',
  LeaveDuration: 'Leave Duration',
  LeaveType: 'Leave Type',
  LeaveNote: 'Leave Note',
  Status: 'Status',
  ApprovedNote: 'Approved Note',
  Rating: 'Rating',
  Leave: 'Leave',
  Absent: 'Absent',
  Holiday: 'Holiday',
  WeekOf: 'Weekly Off',
  TodoList: 'Todo List',
  ToDo: 'Todo',
  OverDue: 'Overdue',
  Collapse: 'Collapse',
  Expand: 'Expand',
  Device: 'Device',
  Browser: 'Browser',
  LastVisit: 'Last Visited',
  totalUsage: 'Total Usage',
  Role_access_list: 'Role Access List',
  email: 'Email',
  phone: 'phone',
  empCode: 'Emp Code',
  DeviceId: 'Device Id',
  DeviceType: 'Device Type',
  DeviceName: 'Device Name',
  GivenName: 'Given Name',
  List: 'List',
  hoursperday: 'Hours Per day',
  TotalHours: 'Total hours',
  BreakFrom: 'Break From',
  BreakTo: 'Break To',
  clicks: 'Clicks',
  keys: 'Keys',
  CurrentLocation: 'Current location',
  selectmapType: 'Select Map Type',
  Name: 'Name',
  FirstName: 'First Name',
  LastName: 'Last Name',
  Company: 'Company',
  city: 'City',
  country: 'Country',
  Teams: 'Teams',
  yettostart: 'Yet to start',
  InProgress: 'In Progress',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  cancel: 'Cancel',
  Total: 'Total',
  //DashBoard Page
  EffortPara: 'Effortlessly track, manage, and optimize employee activities with ease, improving productivity and collaboration across your team.',
  Yettodata: 'No Data yet!',
  Acc_act: 'Application activity not started.',
  Recent_url: 'Recently used website url',
  Track_in_out: 'Track employee IN time and OUT time',
  Span_time: 'Screenshot taken from the employee in a regular span of time',
  Hr: 'hours',
  //(department)sub_department
  sub_dept: 'Add Sub Department',
  sub_name: 'Sub Department Name',
  Enter_title: 'Enter the title',
  cls: 'Close',
  //(department) add_department
  Add_dept: 'Add Department',
  Add_name: 'Department Name',
  edit_dept: 'Edit Department',
  edit_Subdept: 'Edit Sub Department',
  //role access
  select_dept: 'Select Department',
  select_sub_dept: 'Select Sub Department',
  Create_New_Task: 'Create New Task',
  AllTask: 'All Task',
  Starred: 'Starred',
  Trash: 'Trash',
  categories: 'Categories',
  AssignedOn: 'Assigned On',
  TargetDate: 'Target Date',
  Role: 'Role',
  ReportingPerson: 'Reporting Person',
  Select_menu: 'Select Menu',
  Selected_menu: 'Selected Menu',
  Select_emp: 'Select Employee',
  Select_Department: 'Select Department',
  Select_SubDepartment: 'Select Sub Department',
  SelectRole: 'Select Role',
  SelectManager: 'Select Manager',
  SelectTeamMember: 'Select Team Member',
  close: 'Close',
  edit: 'Edit',
  Update: 'Update',
  Select: 'Select',
  enable: 'Enable',
  Password: 'Password',
  Optional: 'Optional',
  Enter: 'Enter',
  Add_Schedule: 'Add Schedule',
  Add_Emp_Schedule: 'Add Employee Schedule',
  Tasks: 'Tasks',
  Category: 'Category',
  SubCategory: 'Sub Category',
  Icon: 'Icon',
  Description: 'Description',
  Continue: 'Continue',
  StartDate: 'Start Date',
  EndDate: 'End Date',
  EstimatedHours: 'Estimated Hours',
  create: 'Create',
  Address: 'Address',
  Contact: 'Contact',
  Postal: 'Postal',
  Website: 'Website',
  Code: 'Code',
  isbillable: 'Is Billable',
  type: 'Type',
  TeamMember: 'Team Member',
  TeamManager: 'Team Manager',
  TotalCost: 'Total Cost',
  projectStatus: 'Project Status',
  notiAt: 'Notify At',
  hourRate: 'Hour Rate',
  Project: 'Project',
  Submit: 'Submit',
  OverView: 'Overview',
  Timesheet: 'Timesheet',
  MessageBoard: 'Message board',
  Budget: 'Budget',
  cost: 'Cost',
  invest: 'Invested',
  worked: 'Worked',
  DueDate: 'Due Date',
  Enterthetitle: 'Enter the Title',
  Enterthename: 'Enter the Name',
  Enterthecode: 'Enter the Emp-Code',
  EnterDeviceName: 'Enter Device Name',
  EnterDeviceId: 'Enter Device Id',
  EnterDeviceType: 'Enter Device Type',
  EnterPassword: 'Enter Password',
  EnterPhone: 'Enter Phone Number',
  EnterGmail: 'Enter the Gmail',
  EnterScheduleName: 'Enter Schedule Name',
  EnterTotalHours: 'Enter Total Hours',
  Enter1Name: 'Enter First Name',
  EnterLastName: 'Enter Last Name',
  EnterCity: 'Enter City',
  EnterCountry: 'Enter Country',
  EnterWebsite: 'Enter Website',
  EnterCompany: 'Enter Company',
  EnterCompanyEmail: 'Enter Company Email',
  EnterCompanyContact: 'Enter Company Contact',
  EnterPostal: 'Enter Postal Code',
  EnterCompanyAddress: 'Enter Company Address',
  EnterDescription: 'Enter the description',
  EnterHourRate: 'Enter Hour Rate',
  EnterBudgetInvest: 'Budget Invested',
  EnterTotalCost: 'Enter Total Cost',
  SelectStatus: 'Select Status',
  EnterHour: 'Enter the Hours',
  SearchForAnything: 'Search for anything ....',
  Search: 'Search',
  Remaininghours: 'Remaining hours',
  Spenthours: 'Spent hours',
  details: 'Details',
  EnterEstimated: 'Enter Estimated hours',
  EnterCategory: 'Enter the Category',
  EnterSubCat: 'Enter Sub Category',
  SignInWfm: 'Sign in to Kafaa Employee',
  SignInAdminWfm: 'Sign in to Kafaa Admin',
  pleaseLogintocontinue: 'Please login to continue',
  submit: 'Submit',
  orloginwithadmin: 'Or login with admin',
  orloginwithEmployee: 'Or login with employee',
  workforcemanagement: 'Work Force Management',
  TrackandReport: 'Track and Report your activity',
  loginDesc: 'Activity Tracking involves monitoring and recording tasks and behaviors over time to analyze productivity, habits, or progress.',
  checkout: 'Check Out our Web app',
  donthaveaccount: "Don't have an account? Create an account",
  From_time: "From Date/Time",
  To_time: "To Date/Time",
  New_Event: "New Event",
  Edit_Event: "Edit Event",
  ADD_Event: "Add Event",
  AddDevice: 'Add New Device',
  Events: "Event",
  Dashboard: "Dashboard",
  save_event: "Save Event",
  Calendar: "Calendar",
  PendingDevice: "Pending Device",
  PendingDeviceList: "Pending Device List",
  condevice: 'Configured Device',
  DeviceID: "Device ID",
  EnterGmailusername: 'Enter email or username',
  SelectedReportingPerson: 'Selected Reporting Person',
  knowlegeTransfer: 'Knowlege Transfer',
  TaskAssign: 'Task Assign',
  Author: 'Author',
  PostedDateTime: 'Posted Date & Time',
  Views: 'Views',
  writeReview: 'Write a review',
  Review: 'Review',
  howexpericence: 'How was your experience?',
  Post: 'Post',
  writtenby: 'Written By',
  Uploadfile: 'Upload File',
  Uploadedfile: 'Uploaded File',
  logoutalert: 'You will be logged out of your account!',
  yeslogout: 'Yes, Log out',
  Time_Interval: 'Time Interval',
  Time_Type: 'Time Type',
  Screen_Interval_List: 'Screen Interval List',
  Add_Screen_Interval: ' Add Screen Interval',
  Edit_Screen_Interval: 'Edit Screen Interval',
  Minutes: 'Minutes',
  Delete: 'Delete',
  OverAllTime: 'OverAllTime',
  Sub_Category_of:'Sub Category Of',
  View:'View',
  EmployeeLog : 'Employee Log'




};

export const arabic = {
  welcome: 'مرحبًا',
  dashboard: 'لوحة التحكم',
  welcomedashboard: 'مرحبًا بعودتك!',
  Employee_Report: 'لوحة تقرير الموظف',
  TODAY_WORKING_HOURS: 'ساعات العمل اليوم',
  TOTAL_IDLE_HOURS: 'إجمالي ساعات الخمول',
  Mouse_Count: 'عدد النقرات',
  Keystroke_Count: 'عدد الضغطات',
  User_Management: 'إدارة المستخدم',
  Activity_Tracking: 'تتبع النشاط',
  Project_Management: 'إدارة المشاريع',
  Add: 'إضافة',
  Share: 'مشاركة',
  Action: 'الإجراء',
  Attendance: 'الحضور',
  Timesheet: 'جدول العمل',
  Department: 'القسم',
  Sub_Department: 'القسم الفرعي',
  Sub_Department_of: 'القسم الفرعي للموارد البشرية',
  Role_Access: 'صلاحيات الدور',
  Employee: 'الموظف',
  Mouse_Keyboard: 'الفأرة ولوحة المفاتيح',
  Application: 'التطبيق',
  URL: 'الرابط',
  Screenshots: 'لقطات الشاشة',
  Location: 'الموقع',
  Projects: 'المشاريع',
  Client: 'العميل',
  Project_Teams: 'فرق المشاريع',
  To_Dos: 'المهام',
  Title: 'العنوان',
  Previous: 'السابق',
  Next: 'التالي',
  Previous_Mouse_click: 'النقرة السابقة',
  Previous_Key_Count: 'عدد الضغطات السابقة',
  Keystroke_Count: 'عدد الضغطات',
  Hours_spend_today: 'الساعات التي قضاها اليوم',
  paragraph: 'تصور وحلل ساعات عمل الموظفين لتحسين إدارة الوقت والإنتاجية.',
  Working_hours: 'ساعات العمل',
  Idle_hours: 'ساعات الخمول',
  Weekly_Report: 'التقرير الأسبوعي',
  paragraph1: 'حالة التقرير، تتبع التقرير الأسبوعي لموظفيك بناءً على الساعات اليومية.',
  Manual_hours: 'الساعات اليدوية',
  Recent_screenshot: 'لقطات الشاشة الأخيرة',
  Employee_Activity: 'نشاط الموظف',
  Employee_Attendence: 'حضور الموظف',
  Recent_Website: 'الموقع الإلكتروني الأخير',
  Department_list: 'قائمة الأقسام',
  Employee_List: 'قائمة الموظفين',
  Mouse_Keyboard: 'الفأرة ولوحة المفاتيح',
  Application_Tracking: 'تتبع التطبيقات',
  URLTrack_Activity: 'تتبع الروابط',
  Timesheet_List: 'قائمة الجداول الزمنية',
  TimeOff: 'وقت الراحة',
  EmployeeDevice: 'جهاز الموظف',
  Schedule: 'الجدول',
  Numbers: 'رقم',
  Dates: 'التاريخ',
  StartTime: 'وقت البدء',
  INTime: 'وقت الدخول',
  OutTime: 'وقت الخروج',
  Rating: 'التقييم',
  OpenTime: 'وقت الفتح',
  EndTime: 'وقت النهاية',
  CloseTime: 'وقت الإغلاق',
  Activity: 'النشاط',
  OverTime: 'العمل الإضافي',
  BreakTime: 'وقت الاستراحة',
  ManualTime: 'الوقت اليدوي',
  ScreenTime: 'وقت الشاشة',
  IdleTime: 'وقت الخمول',
  WorkTime: 'وقت العمل',
  Time: 'الوقت',
  OverallProduction: 'إجمالي الإنتاجية',
  Export: 'تصدير المستخدم',
  Selectfilter: 'اختر الفلتر',
  filter: 'الفلتر',
  LeaveData: 'بيانات الإجازة',
  CurrentWeek: 'الأسبوع الحالي',
  WeeklyPattern: 'النمط الأسبوعي',
  NoData: 'لا توجد بيانات',
  LeaveHistory: 'تاريخ الإجازات',
  RequestLeave: 'طلب إجازة',
  LeaveDuration: 'مدة الإجازة',
  LeaveType: 'نوع الإجازة',
  LeaveNote: 'ملاحظة الإجازة',
  Status: 'الحالة',
  ApprovedNote: 'ملاحظة الموافقة',
  Leave: 'إجازة',
  Absent: 'غياب',
  Holiday: 'عطلة',
  WeekOf: 'إجازة أسبوعية',
  TodoList: 'قائمة المهام',
  ToDo: 'قائمة المهام',
  OverDue: 'متأخر',
  Collapse: 'طي',
  Expand: 'توسيع',
  Device: 'الجهاز',
  Browser: 'المتصفح',
  LastVisit: 'آخر زيارة',
  totalUsage: 'إجمالي الاستخدام',
  Role_access_list: 'قائمة صلاحيات الأدوار',
  email: 'البريد الإلكتروني',
  phone: 'الهاتف',
  empCode: 'رمز الموظف',
  DeviceId: 'معرف الجهاز',
  DeviceType: 'نوع الجهاز',
  DeviceName: 'اسم الجهاز',
  GivenName: 'الاسم المعطى',
  List: 'القائمة',
  hoursperday: 'ساعات في اليوم',
  TotalHours: 'إجمالي الساعات',
  BreakFrom: 'استراحة من',
  BreakTo: 'استراحة إلى',
  clicks: 'النقرات',
  keys: 'الضغطات',
  CurrentLocation: 'الموقع الحالي',
  selectmapType: 'اختر نوع الخريطة',
  Name: 'الاسم',
  FirstName: 'الاسم الأول',
  LastName: 'اسم العائلة',
  Company: 'الشركة',
  city: 'المدينة',
  country: 'الدولة',
  Teams: 'الفرق',
  yettostart: 'لم يبدأ بعد',
  InProgress: 'قيد التنفيذ',
  Completed: 'مكتمل',
  Cancelled: 'ملغى',
  cancel: 'إلغاء',
  Total: 'الإجمالي',
  EffortPara: 'تابع وأدر نشاطات الموظفين بكل سهولة لتحسين الإنتاجية والتعاون في فريقك.',
  Yettodata: 'لا توجد بيانات حتى الآن!',
  Acc_act: 'نشاط التطبيق لم يبدأ بعد.',
  Recent_url: 'الرابط الأخير المستخدم',
  Track_in_out: 'تتبع وقت دخول وخروج الموظف',
  Span_time: 'لقطة الشاشة تم التقاطها في فترات زمنية منتظمة',
  Hr: 'ساعات',
  sub_dept: 'إضافة قسم فرعي',
  sub_name: 'اسم القسم الفرعي',
  Enter_title: 'أدخل العنوان',
  cls: 'إغلاق',
  Add_dept: 'إضافة قسم',
  Add_name: 'اسم القسم',
  edit_dept: 'تحرير القسم',
  edit_Subdept: 'القسم الفرعي',
  select_dept: 'اختر القسم',
  select_sub_dept: 'اختر القسم الفرعي',
  Create_New_Task: 'إنشاء مهمة جديدة',
  AllTask: 'كل المهام',
  Starred: 'المفضلة',
  Trash: 'المهملات',
  categories: 'الفئات',
  AssignedOn: 'تم التعيين في',
  TargetDate: 'تاريخ الهدف',
  Role: 'الدور',
  Select_menu: 'اختر القائمة',
  Selected_menu: 'القائمة المختارة',
  Select_emp: 'اختر الموظف',
  Select_Department: 'اختر القسم',
  Select_SubDepartment: 'اختر القسم الفرعي',
  SelectRole: 'اختر الدور',
  SelectManager: 'اختر المدير',
  SelectTeamMember: 'اختر عضو الفريق',
  close: 'إغلاق',
  edit: 'تحرير',
  Update: 'تحديث',
  Select: 'اختر',
  enable: 'تمكين',
  Password: 'كلمة المرور',
  Optional: 'اختياري',
  Enter: 'أدخل',
  Add_Schedule: 'إضافة جدول',
  Add_Emp_Schedule: 'إضافة جدول الموظف',
  Tasks: 'المهام',
  Category: 'فئة',
  SubCategory: 'الفئة الفرعية',
  Icon: 'أيقونة',
  Description: 'الوصف',
  Continue: 'استمر',
  StartDate: 'تاريخ البدء',
  EndDate: 'تاريخ الانتهاء',
  EstimatedHours: 'الساعات المقدرة',
  create: 'إنشاء',
  Address: 'العنوان',
  Contact: 'الاتصال',
  Postal: 'البريد',
  Website: 'الموقع الإلكتروني',
  Code: 'الرمز',
  isbillable: 'قابل للفوترة',
  type: 'النوع',
  TeamMember: 'عضو الفريق',
  TeamManager: 'مدير الفريق',
  TotalCost: 'التكلفة الإجمالية',
  projectStatus: 'حالة المشروع',
  notiAt: 'إشعار في',
  hourRate: 'معدل الساعة',
  Project: 'مشروع',
  Submit: 'إرسال',
  OverView: 'نظرة عامة',
  Timesheet: 'الجدول الزمني',
  MessageBoard: 'لوحة الرسائل',
  Budget: 'الميزانية',
  cost: 'التكلفة',
  invest: 'مستثمر',
  worked: 'عمل',
  DueDate: 'تاريخ الاستحقاق',
  Enterthetitle: 'أدخل العنوان',
  Enterthename: 'أدخل الاسم',
  Enterthecode: 'أدخل رمز الموظف',
  EnterDeviceName: 'أدخل اسم الجهاز',
  EnterDeviceId: 'أدخل معرف الجهاز',
  EnterDeviceType: 'أدخل نوع الجهاز',
  EnterPassword: 'أدخل كلمة المرور',
  EnterPhone: 'أدخل رقم الهاتف',
  EnterGmail: 'أدخل بريد الجيميل',
  EnterScheduleName: 'أدخل اسم الجدول',
  EnterTotalHours: 'أدخل إجمالي الساعات',
  Enter1Name: 'أدخل الاسم الأول',
  EnterLastName: 'أدخل اسم العائلة',
  EnterCity: 'أدخل المدينة',
  EnterCountry: 'أدخل الدولة',
  EnterWebsite: 'أدخل الموقع الإلكتروني',
  EnterCompany: 'أدخل اسم الشركة',
  EnterCompanyEmail: 'أدخل بريد الشركة',
  EnterCompanyContact: 'أدخل جهة اتصال الشركة',
  EnterPostal: 'أدخل الرمز البريدي',
  EnterCompanyAddress: 'أدخل عنوان الشركة',
  EnterDescription: 'أدخل الوصف',
  EnterHourRate: 'أدخل سعر الساعة',
  EnterBudgetInvest: 'الميزانية المستثمرة',
  EnterTotalCost: 'أدخل التكلفة الإجمالية',
  SelectStatus: 'اختر الحالة',
  EnterHour: 'أدخل الساعات',
  SearchForAnything: 'ابحث عن أي شيء....',
  Search: 'بحث',
  Remaininghours: 'الساعات المتبقية',
  Spenthours: 'الساعات المستهلكة',
  details: 'التفاصيل',
  EnterEstimated: 'أدخل الساعات المقدرة',
  EnterCategory: 'أدخل الفئة',
  EnterSubCat: 'أدخل الفئة الفرعية',
  SignInWfm: 'تسجيل الدخول إلى موظف كفاءة',
  SignInAdminWfm: 'تسجيل الدخول إلى إدارة كفاءة',
  pleaseLogintocontinue: 'يرجى تسجيل الدخول للمتابعة',
  submit: 'إرسال',
  orloginwithadmin: 'أو تسجيل الدخول كمسؤول',
  orloginwithEmployee: 'أو تسجيل الدخول كموظف',
  workforcemanagement: 'إدارة القوى العاملة',
  TrackandReport: 'تتبع وأبلغ عن نشاطك',
  loginDesc: 'تتبع النشاط يتضمن مراقبة وتسجيل المهام والسلوكيات على مدار الوقت لتحليل الإنتاجية والعادات أو التقدم.',
  checkout: 'تحقق من تطبيق الويب الخاص بنا',
  donthaveaccount: 'ليس لديك حساب؟ أنشئ حسابًا',
  From_time: "من التاريخ/الوقت",
  To_time: "إلى التاريخ/الوقت",
  New_Event: "حدث جديد",
  Edit_Event: "تحرير الحدث",
  ADD_Event: "أضف حدثًا",
  AddDevice: 'إضافة جهاز جديد',
  Events: "حدث",
  Dashboard: "لوحة القيادة",
  save_event: "حفظ الحدث",
  Calendar: "تقويم",
  PendingDevice: "الجهاز المعلق",
  PendingDeviceList: "قائمة الأجهزة المعلقة",
  condevice: 'الجهاز المُكوَّن',
  DeviceID: "Device ID",
  EnterGmailusername: "أدخل البريد الإلكتروني أو اسم المستخدم",
  ReportingPerson: 'الشخص المُبلِّغ',
  SelectedReportingPerson: 'الشخص المسؤول المختار',
  knowlegeTransfer: 'نقل المعرفة',
  TaskAssign: 'إسناد المهام',
  Author: 'المؤلف',
  PostedDateTime: 'تاريخ ووقت النشر',
  Views: 'المشاهدات',
  writeReview: 'اكتب تقييمًا',
  Review: 'تقييم',
  howexpericence: 'كيف كانت تجربتك؟',
  Post: 'منشور',
  writtenby: 'كتبه',
  Uploadfile: 'تحميل ملف',
  Uploadedfile: 'الملف المحمَّل',
  logoutalert: 'سيتم تسجيل خروجك من حسابك!',
  yeslogout: 'نعم، تسجيل الخروج',
  Screenshot_Interval: 'الفاصل الزمني للقطة الشاشة',
  Time_Interval: 'الفاصل الزمني',
  Time_Type: 'نوع الوقت',
  Screen_Interval_List: 'قائمة الفاصل الزمني للشاشة',
  Add_Screen_Interval: 'إضافة الفاصل الزمني للشاشة',
  Edit_Screen_Interval: 'تحرير الفاصل الزمني للشاشة',
  Minutes: 'دقائق  ',
  Delete: 'يمسح',
  alert: 'متأكد أنك تريد الحذف',
  OverAllTime: 'في كل الأوقات',
  Sub_Category_of:'الفئة الفرعية',
  View:'منظر',
  EmployeeLog : 'سجل الموظفين'





};